/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type WorkplaceInspectionTemplateDetailFragment = {
  __typename?: 'WorkplaceInspectionTemplate';
  id: any;
  templateName: string;
  name: string;
  description: string;
  type?: Types.WorkplaceInspectionTypeChoices | null;
  status?: Types.WorkplaceInspectionStatusChoices | null;
  workplace?: { __typename?: 'Workplace'; id: any } | null;
  inspectedBy?: { __typename?: 'User'; id: any } | null;
};

export type WorkplaceInspectionTemplateDetailQueryVariables = Types.Exact<{
  workplaceInspectionTemplateDetailId: Types.Scalars['WorkplaceInspectionTemplateID']['input'];
}>;

export type WorkplaceInspectionTemplateDetailQuery = {
  __typename?: 'Query';
  object:
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null }
    | {
        __typename?: 'WorkplaceInspectionTemplate';
        id: any;
        templateName: string;
        name: string;
        description: string;
        type?: Types.WorkplaceInspectionTypeChoices | null;
        status?: Types.WorkplaceInspectionStatusChoices | null;
        workplace?: { __typename?: 'Workplace'; id: any } | null;
        inspectedBy?: { __typename?: 'User'; id: any } | null;
      };
};

export const WorkplaceInspectionTemplateDetailFragmentDoc = gql`
  fragment WorkplaceInspectionTemplateDetail on WorkplaceInspectionTemplate {
    id
    templateName
    workplace {
      id
    }
    inspectedBy {
      id
    }
    name
    description
    type
    status
  }
`;
export const WorkplaceInspectionTemplateDetailDocument = gql`
  query WorkplaceInspectionTemplateDetail($workplaceInspectionTemplateDetailId: WorkplaceInspectionTemplateID!) {
    object: workplaceInspectionTemplateDetail(id: $workplaceInspectionTemplateDetailId) {
      ... on ObjectDoesNotExistResponse {
        objectId
      }
      ... on WorkplaceInspectionTemplate {
        ...WorkplaceInspectionTemplateDetail
      }
    }
  }
  ${WorkplaceInspectionTemplateDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class WorkplaceInspectionTemplateDetailGQL extends Apollo.Query<
  WorkplaceInspectionTemplateDetailQuery,
  WorkplaceInspectionTemplateDetailQueryVariables
> {
  override document = WorkplaceInspectionTemplateDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
